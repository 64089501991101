import logoNoText from "./../(assets)/logojokernotext.svg"
const BackSoon = () => {
    return (
        <div className="tw-flex tw-flex-col tw-h-screen tw-w-screen tw-justify-center tw-items-center">
            {/* <span className="tw-col tw-items-center"> */}
                <img src={logoNoText} alt="Joker Paris" width={300} height={300}/>
                <br></br>
                <br></br>
                <br></br>
                <h3>Le site de Joker Paris fait peau neuve,</h3>
                <h3>on revient très vite !</h3>
            {/* </span> */}
        </div>
    );
}

export default BackSoon;