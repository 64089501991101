import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Button, MantineProvider } from "@mantine/core"


import '@mantine/core/styles.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './routes/Main';
import Expertises from './routes/Expertises';
import BrandContent from './routes/expertises/BrandContent';
import Influences from './routes/expertises/Influences';
import SocialMedia from './routes/expertises/SocialMedia';
import ExperientielEtRelationPublique from './routes/expertises/ExperientielEtRelationPublique';
import Agence from './routes/Agence';
import Projets from './routes/Projets';
import BackSoon from './routes/BackSoon';



const root = ReactDOM.createRoot(document.getElementById('root'));


const router = createBrowserRouter([
  {
    path: "/*",
    element: <BackSoon/>,
    // children : [
    //   {
    //     path:"",
    //     element:<Main/>
    //   },
    //   {
    //     path : "/expertises",
    //     element:<Expertises/>,
    //     children : [{
    //       path:"influence",
    //       element : <Influences/>
    //     },{
    //       path: "brandContentEtCreation",
    //       element: <BrandContent/>,
    
    //     },{
    //       path: "socialMedia",
    //       element: <SocialMedia/>,
          
    //     },{
    //       path: "experientielEtRelationPublique",
    //       element: <ExperientielEtRelationPublique/>,
          
    //     }]
    //   },
    //   {
    //     path: "agence",
    //     element: <Agence/>,
    //   },
    //   { 
    //     path:"projets",
    //     element:<Projets/>

    //   }
    // ]
  }
  // ,{
//     path: "/agence",
//     element: <Agence/>,

//   },{
//     path: "/projets",
//     element: <Projets/>,

//   },
//   {
//     path : "/expertises",
//     element:<Expertises/>,
//     children : [{
//       path:"influence",
//       element : <Influences/>
//     },{
//       path: "brandContentEtCreation",
//       element: <BrandContent/>,

//     },{
//       path: "socialMedia",
//       element: <SocialMedia/>,
      
//     },{
//       path: "experientielEtRelationPublique",
//       element: <ExperientielEtRelationPublique/>,
      
//     }
//     ,{
//       path: "agence",
//       element: <Agence/>,

//     }]
//   }
]);
root.render(
  <React.StrictMode>
    <MantineProvider >
        <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
