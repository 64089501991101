
import { Outlet } from 'react-router';
import './App.css';
import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';
import { useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import { useMemo } from 'react';

function App() {
  const  [scroll, scrollTo ] = useWindowScroll()
  
  return (
    <div className="App">
      <Navigation/>
      <Outlet/>
      <Footer/>
    </div>
  );
}

export default App;
